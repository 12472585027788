nav{
	position:absolute !important;
	top:0 !important;
	left:0 !important;
	display:flex !important;
	flex-direction:column !important;
	justify-content: space-between !important;
	width:19% !important;
	height: 100vh !important;
	background-color: #008080 !important;
	padding:2rem 1rem 1rem 1rem !important;
	transition: transform 1s !important;
	margin: 0 !important;
	z-index: 9999;
}

.navbar{
	transform: translateX(-100%) !important;
}

.nav-btn{
	position: absolute !important;
	transform: translateX(38px) !important;
	top: 20px !important;
	right: 0 !important;
	width:40px !important;
	height: 60px !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	background-color: #008080 !important;
	outline: none !important;
	border: none !important;
	font-size: 1rem !important;
	padding: 5px 10px !important;
	cursor: pointer !important;
	color: #fff !important;
}

.logo{
	display: block !important;
	width: 150px !important;
	margin: auto !important;
	background:transparent !important;
	padding-top: 20px !important;
}

.logo img{
	display: block !important;
	width: 100% !important;
	height: 100% !important;
}

.nav-link{
	display: flex !important;
	align-items: center !important;
	color: #fff !important;
	text-decoration: none !important;
	padding: 10px 1rem !important;
	border-radius: 5px !important;
	margin-bottom: 5px !important;
}

.nav-link:hover{
	background-color: #FFF7E9 !important;
	color: #4e4e4e !important;
}

.nav-link span{
	margin-left: 10px !important;
}

.nav-top{
	margin-top: 10rem !important;
}

.mobile-nav{
	background-color:#008080 !important;
	width: 100% !important;
	height:40px !important;
	display: none !important;
}

.mobile-nav-btn{
	color: #fff !important;
	background: transparent !important;
	outline: none !important;
	border: none !important;
	margin: 0 10px !important;
}

@media only screen and (max-width: 768px){
	nav{
		width: 40% !important;
		padding: 10px !important;
		height: calc(100vh - 38px) !important;
	}
}

@media only screen and (max-width: 450px){
	.mobile-nav{
		position: fixed !important;
		display: flex !important;
		align-items: center !important;
		z-index: 999999999999999999999999;
	}
	nav{
		position: fixed !important;
		width: 70% !important;
		top: 40px !important;
		height: calc(100vh - 40px) !important;
		padding-bottom: 25% !important;
	}

	.nav-btn{
		display: none !important;
	}
}