.custom-dropdown .btn{
    background: #f1f1f1 !important;
    border: none !important;
    color: #008080 !important;
}
.custom-dropdown1 .btn{
  background: #f1f1f1 !important;
  border: none !important;
  color: #333 !important;
  max-width: 260px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}
.custom-dropdown .dropdown-item {
    width: 100% !important;
    max-width: 300px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 20px;
    margin: 0;
    color: #333;
    font-size: 14px;
  }
  .custom-dropdown1 .dropdown-item {
    width: 100% !important;
    max-width: 300px !important;
    padding: 8px 20px;
    margin: 0;
    color: #333;
    font-size: 14px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  }
  .dropdown-menu {
    max-height: 250px; /* définit une hauteur maximale pour la liste déroulante */
    overflow-y: auto; /* ajoute une barre de défilement verticale si la liste est plus grande que la hauteur maximale */
  }

.custom-dropdown .dropdown-item:hover, .custom-dropdown1 .dropdown-item:hover {
    color: #008080 !important;
}
.custom-dropdown .dropdown-item:active, .custom-dropdown1 .dropdown-item:active {
    background: #f1f1f1 !important;
}

.choixX {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 1px solid #999;
    transition: 0.2s all linear;
    margin-top: 6px;
  }
  
  .choixX:checked {
    border: 2px solid #008080;
    color: #008080;
    background: #008080;
    outline: unset !important /* I added this one for Edge (chromium) support */
  }

  .confirmed{
    height: 80px;
    margin-top: 170px;
  }
  .tttt{
    margin-right: 10px !important;
  }
  .btn_edit{
    margin-right: 10px !important;
  }
  .btn_edit:hover{
    color: #ffffff !important;
  }

  .modal-customer{
    background-color: rgba(0, 0, 0, 0.7);

  }

  @media only screen and (min-width: 600px){

    .resp{
      min-width: 500px !important;
  
    }
  }