@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

*{
	padding: 0;
	margin: 0 !important;
	box-sizing: border-box !important;
	font-family: 'Open Sans', sans-serif !important;
}

.App{
	display: flex !important;
	flex-direction: column !important;
	width: 100% !important;
	height: 100vh !important;
	overflow: hidden !important;
	overflow-y: scroll !important;
}

.page{
	flex-basis: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	justify-content:start!important;
	align-items:flex-start !important;
	margin-left: 20px !important;
	transition: margin-left 1s !important;
	margin-top: 70px !important;
}

.page-with-navbar{
	margin-left: 22% !important;
}

@media only screen and (max-width: 768px){
	.page-with-navbar{
		width: 100% !important;
		margin-left: 0 !important;
	}
	.page{
		margin-top: 60px !important;
		margin-left: 0px !important;
	}
	.tableInvoice{
		font-size: 12px !important;
	}
	.App{
		height: 100% !important;
		overflow-y: hidden !important;
	}
	html{
		overflow-y: scroll !important;
	}

}

.btn{
	box-shadow: none !important
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn:focus {
  outline: none;
  box-shadow: none;
  }

input{
	box-shadow: none !important
}

input:focus {
  outline: none;
  box-shadow: none;
}
textarea{
	outline: none;
	box-shadow: none !important;
}
textarea:focus {
	outline: none;
	box-shadow: none;
  }
/* input[type="text"],
textarea {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: transparent !important;
}

.form-control {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
} */